@import url('https://fonts.googleapis.com/css2?family=Arima+Madurai:wght@900&family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arima+Madurai:wght@900&display=swap');

body {
  font-family: source sans pro;
  color: #686868;
  height: 100vh;
  margin: 0;
  padding: 0;
  background-image:url('./img/Map.png'), linear-gradient(to bottom, #E1ECE7 , #EAEFED);
  background-repeat: no-repeat;
  background-size: 85%, auto;
  background-position: center center;
}

h2 {
  font-family: arima madurai;
  color: #68947F;
  font-size: 32px;
  letter-spacing: -1px;
  margin: 0;
}

h3 {
  font-size: 32px;
  letter-spacing: -1px;
  margin: 0;
}

input{
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
  width: 100%;
  border-radius: 10px;
  padding: 15px 4px 15px 44px;
  background: rgba(255,255,255,0.5) url('./img/search-icon.png') no-repeat 15px center;
  margin-bottom: 20px;
  font-size: 16px;
  font-family: source sans pro;
  font-weight: 600;
  color: #686868;
  border: 1px solid #68947F;
  outline: none;
}

::placeholder{
  color: #999999;
}

a{
  color: #68947F;
}

#root{
  display: flex;
  justify-content: center;
}

.flex-space-between{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.extra-padding{
  padding: 16px;
}

#drop-shadow{
  -webkit-box-shadow: 0px 14px 14px 2px rgba(162,190,164,0.25); 
  box-shadow: 0px 14px 14px 2px rgba(162,190,164,0.25);
}

.container{
  width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header{
  padding-top: 30px;
  width: 100%;
}

.info{
  color: #68947F;
  font-weight: 600;
  width: 26px;
  height: 26px;
  border: 2px solid #68947F;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-container{
  width: 858px;
  padding-top: 135px;
}

.results-container{
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
  width: 100%;
  background-color: #FBFBFB;
  border-radius: 10px;
  padding: 6px;
  font-weight: 500;
  color: #686868;
}

.country-result{
  margin: 0;
}

.country-button{
  padding: 12px 0 12px 38px;
  width: 100%;
  font-size: 16px;
  font-family: source sans pro;
  font-weight: 600;
  color: #686868;
  text-align: left;
  border-radius: 4px;
  border: none;
  background: transparent url('./img/circle.png') no-repeat 9px center;

}

.country-button:hover{
  background-color: #EFF3F1;
}

.country-info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.country-info-bg{
  background-color: #EFF3F1;
  border-radius: 4px;
  margin-bottom: 6px;
  width: 96.4%;
}

.weather-icon{
  background-color: #FBFBFB;
  padding: 20px;
  border-radius: 10px;
}

.reset-button{
  padding: 16px 24px 16px 24px;
  font-size: 16px;
  font-family: source sans pro;
  font-weight: 600;
  color: #686868;
  text-align: left;
  border-radius: 4px;
  border: 1px solid #68947F;
}

.reset-button:hover{
  background-color: #ffffff;
}

.footer{
  position:absolute;
  bottom:0;
  width: 1440px;
  padding: 16px 0 42px 0;
  border-top: 1px solid #68947F;
}